import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer>
        <p>Kontaktdaten:</p>
        <div class="decoration"></div>

        <ul class="kontaktdaten">
          <li>Eventindustry GmbH</li>
          <li>CH 3110 Münsingen</li>
          <li>Tel: +41 79 939 34 77</li>
          <li>E-Mail: info@eventindustry.ch</li>
        </ul>

        <table>
          <tr>
            <th>
              <Link to="/kontakt">Kontakt</Link>
            </th>
            <th>
              <Link to="/impressum">Impressum</Link>
            </th>
            <th>
              <a href="/pdf/AGB_Eventindustry_GmbH.pdf"  target="_blank" rel="noopener noreferrer">AGB
              </a>
              </th>
          </tr>
        </table>

        <div class="decoration_2"></div>

        <ul class="kontaktlink">
          <li>
            <Link to="/kontakt">Kontakt</Link>
          </li>
          <li>
            <Link to="/impressum">Impressum</Link>
          </li>
          <li>
          <a href="/pdf/AGB_Eventindustry_GmbH.pdf"  target="_blank" rel="noopener noreferrer">AGB
          </a>
          </li>
        </ul>

        <div class="decoration_3"></div>
        <div class="decoration_4"></div>
        <div class="decoration_5"></div>
      </footer>
      <div className="createdby">
        © 2025 Copyright | Created by Jonas Hafner
      </div>
    </>
  );
}

export default Footer;
